import { Col, Row, Tabs, Typography } from "antd";
import DataPackages from "Pages/DataPackage/DataPackage";
import AdvancedList from "Pages/ManageDatasets/AdvancedList";
import React from "react";
import { useSelector } from "react-redux";
import AdvancesListTable from "./AdvancesListTable";

const ManageAdvancedList = () => {
  const { Title } = Typography;
  const { TabPane } = Tabs;

  const { isAdvancedListsVisibility } = useSelector(
    (state) => state.settingReducer
  );

  console.log("isAdvancedListsVisibility :: ", !isAdvancedListsVisibility);

  const tabPaneData = [
    { tab: "Advanced List", key: "AdvancedList", component: <AdvancedList /> },
    { tab: "Data Package", key: "DataPackage", component: <DataPackages /> },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <Title level={3} className="">
            Manage Data Package
          </Title>
          <Row className="d-flex gap-3" align="middle" justify="space-between">
            {/* <div className="d-flex align-items-center gap-3">
              <Image preview={false} src={FileIcon} alt="..." />
              <Title level={4} className="data-source-title m-0">
                View the data of the data sets as below
              </Title>
              {/* {isLoading ? <Loader /> : null} 
            </div> */}

            {/* <Button
              type="primary"
              // onClick={() => setAddNewModal(true)}
              // disabled={isDisableAdvancedList}
            >
              Add New
            </Button> */}
          </Row>
        </Col>
        <Col span={24}>
          <Tabs
            // defaultActiveKey="data-source"
            // activeKey={activeKey}
            tabPosition="top"
            className="school-details organization"
            // onChange={(e) => setCurrentActiveKey(e)}
          >
            {tabPaneData.map((tabPaneItem) => {
              return (
                <>
                  <TabPane tab={tabPaneItem?.tab} key={tabPaneItem?.key}>
                    {tabPaneItem?.component}
                  </TabPane>
                </>
              );
            })}
          </Tabs>
        </Col>
      </Row>
      <AdvancesListTable />
    </>
  );
};

export default ManageAdvancedList;
