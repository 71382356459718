//** React Imports */
import React, { useEffect, useState } from "react";
//** Router Layout Imports */
import { RoutesLayout } from "./AllRoutes";
//** React Router Imports */
import { Switch, BrowserRouter, Redirect } from "react-router-dom";
//** History Imports */
import { createBrowserHistory } from "history";
//** All pages Imports */
import { Pages } from "Pages/Pages";
import { useDispatch, useSelector } from "react-redux";
import { Hub } from "aws-amplify";
//** Redux Actions Imports */
import { setLoginData } from "store/actions/loginAction";
import { message } from "antd";

export const Routes = () => {
  //** Destructing Routes Layout */
  const { PublicLayoutRoutes, AccountLayoutRoutes, MainLayoutRoutes } =
    RoutesLayout;

  //** Destructing Pages */
  const {
    Help,
    Home,
    Admin,
    Profile_Module,
    Account_Setup_Module,
    Manage_Files_Module,
    Manage_Datasets_Module,
    Data_Management_Module,
    Auth_Module,
    Rule_Engine_Module,
    Data_Package_Module,
    Manage_AdvancedList_Module,
    Manage_AllData_Module,
    Manage_NewData_Module,
    Manage_Datasets_And_Rules_Engine_Module,
    QuickSightDashboard_Module,
  } = Pages;

  //** Destructing Login Module */
  const {
    SIGNIN,
    SIGNUP,
    FORGETPASSWORD,
    RESETPASSWORD,
    CREATEPASSWORD,
    CONFIRMEMAIL,
  } = Auth_Module;

  //** Destructing Profile Module */
  const { CompanySettings, ProfileSettings } = Profile_Module;

  //** Destructing Account Setup */
  const { Selection } = Account_Setup_Module;

  const { Data_Provider, Configure_Module } = Data_Management_Module;

  const { Advanced_List, DataSet } = Configure_Module;

  const { RuleEngine, AddNewRuleEngine } = Rule_Engine_Module;
  const { DataPackage } = Data_Package_Module;
  const { ManageAdvancedList } = Manage_AdvancedList_Module;
  const { ManageAllDataModule } = Manage_AllData_Module;
  const { ManageNewDataModule } = Manage_NewData_Module;
  const { ManageDatasetsAndRulesEngine } =
    Manage_Datasets_And_Rules_Engine_Module;

  const { QuickSightDashboardModule, QuickSightDashboardViewModule } =
    QuickSightDashboard_Module;

  const PUBLIC_ROUTES = [
    {
      Path: "/signin",
      Component: SIGNIN,
    },
    {
      Path: "/signup",
      Component: SIGNUP,
    },
    {
      Path: "/forgotpassword",
      Component: FORGETPASSWORD,
    },
    {
      Path: "/confirm_email",
      Component: CONFIRMEMAIL,
    },
    {
      Path: "/confirm_email?confirmationcode=:code&email:emailId",
      Component: CONFIRMEMAIL,
    },
    {
      Path: "/reset-password",
      Component: RESETPASSWORD,
    },
    {
      Path: "/create-password",
      Component: CREATEPASSWORD,
    },
  ];

  const ACCOUNT_LAYOUT_ROUTES = [
    {
      Path: "/setup",
      Component: Selection,
    },
    {
      Path: "/setup?requestid=:id",
      Component: Selection,
    },
  ];

  const MAIN_LAYOUT_ROUTES = [
    {
      Path: "/school-settings",
      Component: CompanySettings,
    },
    {
      Path: "/profile",
      Component: ProfileSettings,
    },
    {
      Path: "/",
      Component: Home,
    },
    {
      Path: "/admin",
      Component: Admin,
    },
    {
      Path: "/help",
      Component: Help,
    },
    {
      Path: "/managedatasets",
      Component: Manage_Datasets_Module,
    },

    {
      Path: "/datasets",
      Component: DataSet,
    },
    {
      Path: "/integration",
      Component: Data_Provider,
    },
    {
      Path: "/qs-dashboard",
      Component: QuickSightDashboardModule,
    },
    {
      Path: "/qs-dashboard/:dashboardName",
      Component: QuickSightDashboardViewModule,
    },
    {
      Path: "/datapackage",
      Component: DataPackage,
    },
    // {
    //   Path: "/manage-advancedlist",
    //   Component: ManageAdvancedList,
    // },
    {
      Path: "/rules-and-policies",
      Component: RuleEngine,
    },
    {
      Path: "/rules-and-policies/add-new",
      Component: AddNewRuleEngine,
    },
    {
      Path: "/manage-datasets-and-rules-engine",
      Component: ManageDatasetsAndRulesEngine,
    },
    {
      Path: "/manage-alldata",
      Component: ManageAllDataModule,
    },
    {
      Path: "/configure-data",
      Component: ManageNewDataModule,
    },
  ];

  const { isManageFilesVisibility, isAdvancedListsVisibility } = useSelector(
    (state) => state.settingReducer
  );

  // const { token, groups, userData } = useSelector(
  //   (state) => state.loginReducer
  // );

  const dispatch = useDispatch();
  const customHistory = createBrowserHistory();

  const dispatchUser = (currentAuthenticatedUser) => {
    const { signInUserSession, attributes } = currentAuthenticatedUser;
    dispatch(setLoginData(signInUserSession, attributes));
    customHistory.push("/");
  };

  // useEffect(() => {
  //   Hub.listen("auth", ({ payload }) => {
  //     const { event } = payload;

  //     console.log("auth", payload);

  //     switch (event) {
  //       case "signIn":
  //         dispatchUser(payload.data);
  //         break;
  //       // case "autoSignIn":
  //       //   // dispatchUser(payload.data);
  //       //   break;
  //       case "confirmSignUp":
  //         customHistory.push("/signin");
  //         window.location.reload();
  //         break;
  //       case "autoSignIn_failure":
  //         message.error("Autosign failed. Please signin manually. ");
  //         customHistory.push("/signin");
  //         window.location.reload();
  //         break;
  //       default:
  //         break;
  //     }
  //   });
  //   // eslint-disable-next-line
  // }, []);

  return (
    <BrowserRouter basename="/" history={customHistory}>
      <Switch>
        {PUBLIC_ROUTES.map((ROUTE, index) => {
          const { Path, Component } = ROUTE;
          return (
            <PublicLayoutRoutes
              key={index}
              exact
              path={Path}
              render={(matchprops) => <Component {...matchprops} />}
            />
          );
        })}

        {ACCOUNT_LAYOUT_ROUTES.map((ROUTE, index) => {
          const { Path, Component } = ROUTE;
          return (
            <AccountLayoutRoutes
              key={index}
              exact
              path={Path}
              render={(matchprops) => <Component {...matchprops} />}
            />
          );
        })}

        {MAIN_LAYOUT_ROUTES.map((ROUTE, index) => {
          const { Path, Component } = ROUTE;
          return (
            <MainLayoutRoutes
              key={index}
              exact
              path={Path}
              render={(matchprops) => <Component {...matchprops} />}
            />
          );
        })}

        {!isManageFilesVisibility ? (
          <MainLayoutRoutes
            exact
            path={"/managefiles"}
            render={(matchprops) => <Manage_Files_Module {...matchprops} />}
          />
        ) : null}

        {!isAdvancedListsVisibility ? (
          <MainLayoutRoutes
            exact
            path={"/advancedlist"}
            render={(matchprops) => <Advanced_List {...matchprops} />}
          />
        ) : null}

        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
