//** React imports */
import React from "react";
//** Ant components imports */
import { Tabs } from "antd";
//** Tabs imports */
import Companies from "./Companies";
import Users from "./Users";
//** Redux imports */
import { useDispatch } from "react-redux";
//** Redux action imports */
import {
  updateCompanyAccount,
  setIsScopeNormalUser,
  setSchoolData,
} from "store/actions/loginAction";
//** Styles imports */
import "./Admin.less";
//** Constant imports  */
import { MiscellaneousText } from "utility/constants";
//** Third Party imports */
import { useTranslation } from "react-i18next";

/**
 * Admin index component
 * @function Admin
 * @return {<Admin Content/>}
 */
export default function Admin() {
  //** Destructing Ant Components  */
  const { TabPane } = Tabs;
  //** Destructing Hooks */
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //** Destructing Constants*/
  const { schools, users } = MiscellaneousText;

  //** Defining Select Company */
  const selectCompany = async (accountId, from) => {
    console.log("accountId :: ", accountId);
    switch (from) {
      case "user":
        if (
          accountId?.role === "normal_user" ||
          accountId?.role === "SchoolAdmin"
        ) {
          dispatch(setIsScopeNormalUser(true));
        } else {
          dispatch(setIsScopeNormalUser(false));
        }
        dispatch(updateCompanyAccount(accountId?.Company_Data));
        dispatch(setSchoolData(accountId?.Company_Data));
        break;
      default:
        dispatch(updateCompanyAccount(accountId));
        dispatch(setSchoolData(accountId));
        break;
    }

    setTimeout(() => {
      window.location.href = "/";
    }, 500);
  };

  return (
    // Define background for admin panel and wrapper class
    <div className="sub-container">
      {/* Tabs layout for admin panel Start */}
      <Tabs className="admin-tabs" centered>
        <TabPane
          tab={
            <h3 className="tab-header fs-18 font-bold mb-0">{t(schools)} </h3>
          }
          key="1"
        >
          {/* Company Tab Content */}
          <Companies selectCompany={selectCompany} />
        </TabPane>
        <TabPane
          tab={
            <h3 className="tab-header fs-18 font-bold mb-0"> {t(users)} </h3>
          }
          key="2"
        >
          {/* User Tab Content */}
          <Users selectCompany={selectCompany} />
        </TabPane>
      </Tabs>
      {/* Tabs layout for admin panel End */}
    </div>
  );
}
