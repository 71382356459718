import { listDataPackageDetails } from "graphql/queries";
import { GraphqlOperationExecutor, apiExecuter } from "utility/commonMethods";
import { API } from "aws-amplify";

const getDatapackage = async ({ accountId, package_name }) => {
  const queryData = {
    query: listDataPackageDetails,
    body: {
      limit: 999,
      filter: {
        school_id: { eq: accountId },
        // package_name: {
        //   eq: package_name,
        // },
      },
    },
  };

  return await GraphqlOperationExecutor(queryData);
};

const deleteDataPackage = async ({ accountId, datapackage }) => {
  return apiExecuter(async () => {
    const url = `/deletedatapackage`;
    const body = {
      accountId,
      datapackage,
    };
    return await API.post("schoolBIREST", url, { body });
  });
};

const ManageDatapackageSDK = {
  getDatapackage,
  deleteDataPackage,
};

export default ManageDatapackageSDK;
